import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Quiz from "./components/Quiz2"; // Quiz for home page
import QuestionBankQuiz from "./components/Quiz"; // Quiz for Question Bank page
import Footer from "./components/Footer";
import StateLanguageModal from "./components/StateLanguageModal";
import "./App.css";
import logo from "./assets/2.png";
import book from "./assets/book.png";
import quizpng from "./assets/quiz.png";
import "@fontsource/lato";
import Disclaimer from "./components/Disclaimer";
import RTOPrivacypolicy from"./components/RTOPrivacypolicy"
const height = window.innerHeight

function App() {
  const { i18n } = useTranslation();
  const [isModalVisible, setModalVisible] = useState(false);
  const [language, setLanguage] = useState("English");
  const [state, setState] = useState("Gujarat");
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  console.log("Height",height);
  

  const handleLanguageChange = (e) => {
    const selectedLanguage = e;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  const handleStateChange = (e) => {
    const selectState = e;
    setState(selectState);
  };

  return (
    <div className="app" style={{height:height}}>
      <Router>
        <header className="header">
          <div className="header-left">
            <img src={logo} alt="RTO Exam Logo" className="header-logo" />
            <div className="state-language" onClick={openModal}>
              <span>{state + " " + language}</span>
              <span className="dropdown-arrow">▼</span>
            </div>
          </div>

          <div className="header-right">
            <div className="header-button">
              <Link to="/" className="header-item">
                <img
                  src={book}
                  className="headerImage"
                  width={30}
                  height={30}
                />
                <span>Question Bank</span>
              </Link>
            </div>
            <div className="header-button">
              <Link to="/quiz" className="header-item">
                <img
                  src={quizpng}
                  className="headerImage"
                  width={30}
                  height={30}
                />
                <span>Exam</span>
              </Link>
            </div>
          </div>
        </header>
        <Routes>
          <Route
            path="/"
            element={
              <div className="home">
                <h2>Welcome TO Quiz App</h2>
                <Quiz language={language} />
              </div>
            }
          />

          <Route
            path="/quiz"
            element={<QuestionBankQuiz language={language} />}
          />
          
          <Route
            path="/disclaimer"
            element={ <Disclaimer/>}
          />
          
          <Route
            path="/RTOPrivacypolicy"
            element={ <RTOPrivacypolicy/>}
          />
        </Routes>
        <Footer 
        
        />
      </Router>
      <StateLanguageModal
        show={isModalVisible}
        handleClose={closeModal}
        setModalVisible={(e) => {
          setModalVisible(e);
        }}
        handleLanguageChange={(e) => {
          handleLanguageChange(e);
        }}
        handleStateChange={(e) => {
          handleStateChange(e);
        }}
      />
    </div>
  );
}

export default App;

