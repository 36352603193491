import React from "react";
import logo from "../assets/4.png";
import "./Footer.css";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  return (
    <div className="footer">
      <img
        src={logo}
        alt="Quiz App Logo"
        className="footer-logo"
        width="auto"
        height="auto"
      />
      <div className="right-container-box">
        {/* Link to the Disclaimer page */}
        <Link
          to="/disclaimer" // Use the correct path here
          style={{
            color: "white",
            textDecoration: "none",
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginBottom: 10,
          }}
        >
          Disclaimer
        </Link>

        {/* Link to Privacy Policy */}
        <Link
          to="/RTOPrivacypolicy" // Use the correct path here
          style={{
            color: "white",
            textDecoration: "none",
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginBottom: 10,
          }}
        >
          Privacy Policy
        </Link>

        <p>&copy; 2024 Quiz App. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
