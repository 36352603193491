import React from 'react';
import './RTOPrivacypolicy.css';

export default function RTOPrivacypolicy() {
  return (
    <div className="rto-privacy-policy">
      <h1>R.T.O Quiz Privacy Policy</h1>
      <p>
        R.T.O Quiz regards your privacy and will protect it. This Privacy Policy provides information about the kind of information we collect, how it is used, and the rights you have regarding your information. If you use our service, you agree to what we describe here.
      </p>
      <h2>1. Information Collected</h2>
      <p>
        The following is collected:
        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, and any other information you provide when signing up or participating in quizzes.
          </li>
          <li>
            <strong>Automatically Collected Information:</strong> IP address, type of browser in use, device information, and information about how the app is used.
          </li>
          <li>
            <strong>Cookies and Tracking Technologies:</strong> We use cookies to personalize your experience and analyze traffic.
          </li>
        </ul>
      </p>
      <h2>2. Use of Your Information</h2>
      <p>
        We use the gathered information for:
        <ul>
          <li>Delivering custom quiz results and developing our service;</li>
          <li>Customer service for troubleshooting technical issues;</li>
          <li>Showing relevant advertisements, including but not limited to Google AdSense.</li>
        </ul>
      </p>
      <h2>3. Third-Party Advertisement: Google AdSense</h2>
      <p>
        We use Google AdSense to deliver ads to the users. AdSense can use cookies to target the ads you view according to your history of surfing web pages. For more information on how Google manages your data, please refer to Google's Privacy Policy.
      </p>
      <h2>4. Cookies</h2>
      <p>
        Cookies are small files that are transferred to your device to enhance user experience. You may have a choice regarding cookies via your browser's settings. Disabling cookies may impair some functions of the website.
      </p>
      <h2>5. Third Party Links</h2>
      <p>
        Our site may contain links to other web sites or third-party resources. We do not control, cannot guarantee the accuracy of, nor do we promise responsibility for the privacy practices of linked third-party sites and their sponsors. These practices vary.
      </p>
      <h2>6. Data Protection</h2>
      <p>
        We respect your right to privacy. But no method of electronic transmission or storage is totally secure.
      </p>
      <h2>7. Your Choices</h2>
      <p>
        You have certain rights regarding your information:
        <ul>
          <li>Access and update your personal information.</li>
          <li>Opt-out of receiving commercial email messages from us by managing your preferences here.</li>
        </ul>
      </p>
      <h2>8. Changes to this Policy</h2>
      <p>
        We reserve the right to amend this Privacy Policy at any time. We will post all changes on this page with an updated effective date.
      </p>
      <h2>9. Contact Us</h2>
      <p>
        If you have questions, please don't hesitate to contact us at:
        <br />
        Email: quizrto@gmail.com
        <br />
        Address: 904, Shukan Sky, Katargam, Surat-395004.
      </p>
    </div>
  );
}
