import React, { useState, useEffect } from "react";
import quizEnglish from "../Data/question_english.json";
import quizGujarati from "../Data/question_gujarati.json";
import quizHindi from "../Data/question_hindi.json";
import arrow from "../assets/right_Arrow.png";
import "./Quiz.css";

const Quiz = ({ language }) => {
  const [questions, setQuestions] = useState(quizEnglish.result);

  useEffect(() => {
    switch (language) {
      case "Gujarati":
        setQuestions(quizGujarati.result);
        break;
      case "Hindi":
        setQuestions(quizHindi.result);
        break;
      default:
        setQuestions(quizEnglish.result);
    }
  }, [language]);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [showCorrect, setShowCorrect] = useState(false);

  const labels = ["A", "B", "C", "D"];

  const handleAnswer = (option) => {
    setSelectedAnswer(option);
    setShowCorrect(true);

    if (option === questions[currentQuestion].answer) {
      setFeedback("Correct!");
    } else {
      setFeedback("Wrong Answer.");
    }
  };

  const nextQuestion = () => {
    setSelectedAnswer(null);
    setFeedback("");
    setShowCorrect(false);
    setCurrentQuestion((prev) => (prev + 1 < questions.length ? prev + 1 : 0));
  };

  return (
    <div className="mainContainer">
      <div className="addContainer"></div>
      <div className="quiz">
        <p>
          {currentQuestion + 1}: {questions[currentQuestion].question}
        </p>
        <div className="options">
          {questions[currentQuestion].options.map((option, index) => (
            <button
              key={option}
              onClick={() => handleAnswer(option)}
              className={
                showCorrect && option === questions[currentQuestion].answer
                  ? "correct"
                  : selectedAnswer === option
                  ? "wrong"
                  : ""
              }
              disabled={selectedAnswer}
            >
              {`${labels[index]}. ${option}`}
            </button>
          ))}
        </div>
        {feedback && <p className="feedback">{feedback}</p>}
        <button
          onClick={nextQuestion}
          disabled={!selectedAnswer}
          className="nextBtn"
        >
          Next Question <img src={arrow} className="arrowImage" style={{marginLeft:10}}/>
        </button>
      </div>
      <div className="addContainer"></div>
    </div>
  );
};

export default Quiz;
