import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; 
import '@fontsource/lato';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
);
