import React from "react";

export default function Disclaimer() {
  const styles = {
    container: {
      backgroundColor: "#f9f9f9",
      width: "60%",
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      boxSizing: "border-box",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      height: "100%",
      alignSelf: "center",
      marginTop: "20px",
      marginBottom: "20px",
    },
    "@media (max-width: 768px)": {
      container: {
        padding: "15px",
        fontSize: "14px",
      },
    },
    "@media (max-width: 480px)": {
      container: {
        padding: "10px",
        fontSize: "12px",
      },
    },
  };

  return (
    <div style={styles.container}>
      <p>
        <strong>Disclaimer</strong>
        <br />
        It is intended only for public use. Although great efforts have been
        made to make the material contained herein as accurate as possible, it
        cannot be guaranteed or ensured to be comprehensively correct. The
        application does not warrant the accuracy, completeness, or usefulness
        of the information herein. Users should check with the Transport
        Department for confirmation of any information obtained here.
      </p>
    </div>
  );
}
