import React, { useState } from "react";
import "./Modal.css"; // Create a separate CSS file for styling
import { useTranslation } from "react-i18next";
import cityData from "../Data/cities.json";

const StateLanguageModal = ({ show, handleClose,setModalVisible,handleLanguageChange,handleStateChange}) => {
  const [state, setState] = useState("Andhra Pradesh");
  const [language, setLanguage] = useState("English");
  const [selectedCity, setSelectedCity] = useState("");
  const languages = ["English", "Hindi", "Gujarati"];
  const stateCityData = cityData.result.reduce((acc, item) => {
    acc[item.stateName] = item.cityname;
    return acc;
  }, {});
  const { t } = useTranslation();

  if (!show) return null;

  return (
    <div className="modal-backdrop" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h3>Select State / Union Territory & Language</h3>

        <div className="dropdown-container">
          <label>
            Please choose your preferred state / union territory and language
          </label>

          <select value={state} onChange={(e) => {setState(e.target.value); handleStateChange(e.target.value)}}>
            <option value="">{t("select_state")}</option>
            {Object.keys(stateCityData).map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>

          <select
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            disabled={!state}
          >
            <option value="">{t("select_city")}</option>
            {state &&
              stateCityData[state].map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
          </select>

          <select
            value={language}
            onChange={(e) => {
            setLanguage(e.target.value);
              handleLanguageChange(e.target.value)
              setModalVisible(false)
            }}
          >
            {languages.map((langOption, index) => (
              <option key={index} value={langOption}>
                {langOption}
              </option>
            ))}
          </select>
        </div>

        <p className="disclaimer">
          <strong>Disclaimer:</strong> This test is only for public awareness.
          Though all efforts have been made to ensure the accuracy of the
          content, the same should not be construed as a statement of law or
          used for any legal purposes. This application accepts no
          responsibility in relation to the accuracy, completeness, usefulness,
          or otherwise, of the contents. Users are advised to verify/check any
          information with the Transport Department.
        </p>
      </div>
    </div>
  );
};

export default StateLanguageModal; // Export the component for use in other parts of the application.
