import React, { useState, useEffect } from "react";
import quizEnglish from "../Data/question_english.json";
import quizGujarati from "../Data/question_gujarati.json";
import quizHindi from "../Data/question_hindi.json";
import "./quiz2.css";
const Quiz = ({ language }) => {
  const [questions, setQuestions] = useState(quizEnglish.result);

  useEffect(() => {
    switch (language) {
      case "Gujarati":
        setQuestions(quizGujarati.result);
        break;
      case "Hindi":
        setQuestions(quizHindi.result);
        break;
      default:
        setQuestions(quizEnglish.result);
    }
  }, [language]);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  console.log("questions,questions", questions);

  return (
    <div className="mainContainer">
      <div className="addContainer"></div>
      <div className="quiz">
        {questions.map((item, index) => {
          return (
            <div
              className="quizBox"
              style={{
                borderBottomStyle:
                  index === questions.length - 1 ? "none" : "solid",
              }}
              key={index}
            >
              <p>
                {index + 1}: {item.question}
              </p>
              <div className="correct-answer">
                <p>A: {item.answer}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="addContainer"></div>
    </div>
  );
};

export default Quiz;
