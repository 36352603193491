import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      quiz_app: 'Quiz App',
      cities: 'Cities',
      title: 'Welcome to the Quiz App',
    },
  },
  gu: {
    translation: {
      quiz_app: 'ક્વિઝ એપ',
      cities: 'શહેરો',
      title: 'ક્વિઝ એપમાં આપનું સ્વાગત છે',
    },
  },
  hi: {
    translation: {
      quiz_app: 'क्विज ऐप',
      cities: 'शहर',
      title: 'क्विज ऐप में आपका स्वागत है',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Default language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
